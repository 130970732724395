import baseTheme from "@nextblick/gatsby-theme-hdb/src/gatsby-plugin-theme-ui";
import { merge } from "theme-ui";

// merge will deeply merge custom values with the theme's defaults
export default merge(baseTheme, {
  fonts: {
    body: "'Century Gothic', sans-serif",
    heading: "'Century Gothic Std', sans-serif",
    monospace: "Menlo, monospace",
  },
});
